<template>
  <v-autocomplete
    v-model="value"
    :items="items"
    :loading="loading"
    :rules="rules"
    :search-input.sync="search"
    :color="color"
    hide-no-data
    hide-selected
    item-text="label"
    item-value="id"
    :label="`${$t(label)}${(required) ? ' *' : ''}`"
    :prepend-icon="icon"
    chips
    :cache-items="multiple"
    :multiple="multiple"
    :disabled="fieldReadonly"
    :readonly="fieldReadonly"
    :clearable="!fieldReadonly"
    v-on:click:clear="value = undefined"
  >
    <v-tooltip slot="append" bottom v-if="$t(description)">
      <template v-slot:activator="{ on }">
        <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
      </template>
      <span>{{ $t(description) }}</span>
    </v-tooltip>
  </v-autocomplete>
</template>
<script>
import _ from '@/misc/lodash';
import dataAccess from '@/misc/dataAccess';
import baseFields from '@/mixins/baseFields';
import baseTextInput from '@/mixins/baseTextInput';

export default {
  mixins: [
    baseFields,
    baseTextInput,
  ],
  props: {
    label: {
      type: String,
      default: 'fields.autocomplete',
    },
    description: {
      type: String,
      default: 'fields.autocompleteTooltip',
    },
    labelPath: {
      type: String,
      default: '',
    },
    default: {
      type: [String, Array],
      default: () => ([]),
    },
    autocompletePath: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    value: null,
    loading: false,
    search: null,
    selected: [],
    autocomplete: [],
  }),
  computed: {
    items() {
      return [
        ...this.selected,
        ...this.autocomplete,
      ];
    },
    selectedQuery() {
      if (!_.isEmpty(this.value)) {
        return { [this.objectPath || 'id']: this.value };
      }
      return null;
    },
    autocompleteQuery() {
      if (typeof this.search === 'string' && this.search.trim().length > 0) {
        let autocompletePath = this.autocompletePath || this.labelPath;
        if (!Array.isArray(autocompletePath)) {
          autocompletePath = [autocompletePath];
        }
        return autocompletePath.reduce((acc, name) => ({ ...acc, [name]: this.search }), {});
      }
      return null;
    },
  },
  watch: {
    selectedQuery: {
      async handler(query) {
        this.selected = [];
        if (query !== null) {
          this.selected = await this.fetchData(query);
        }
      },
      immediate: true,
      deep: true,
    },
    async autocompleteQuery(query) {
      if (query !== null) {
        this.autocomplete = await this.fetchData(query);
      }
    },
    value(value) {
      this.search = null;

      this.$emit('change', value);
    },
  },
  /**
   * Handle type for multiple value in components not in mixin
   * to prevent site effect with other components
   */
  created() {
    // Default value is handle in baseTextInput mixin
    let { value } = this;

    if (this.multiple) {
      // Normalize value if multiple is enable
      if (!Array.isArray(value)) {
        if (!_.isEmpty(value)) {
          value = [value];
        } else {
          value = [];
        }
      }
    } else if (Array.isArray(value)) {
      // Normalize value if multiple is disable and receive an array
      ([value] = value);
    }

    this.value = value;
  },
  methods: {
    /**
     * Fetch data with filters
     * It's used to fetch selected data and autocomplete
     *
     * @see Watch
     */
    async fetchData(filters) {
      this.loading = true;
      let result = [];
      const { body } = await this.$store.dispatch('crud/SEARCH', {
        object: this.object,
        alias: this.alias,
        body: {
          filters,
          ...this.queryParams,
        },
        store: false,
      });

      result = (body || []).map((hit) => ({
        id: hit.id,
        label: dataAccess.get(hit, this.labelPath),
      }));

      this.$nextTick(() => {
        this.loading = false;
      });
      return result;
    },
  },
};
</script>
